import React from 'react'
import OuterRoutes from './routes/OuterRoutes'
import InnerRoutes from './routes/InnerRoutes'
import Header from './partials/Header'
import InnerHeader from './partials/InnerHeader'
import Sidebar from './partials/Sidebar'
// import Footer from "../helper/Footer";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts'
// import { toaster } from "../../helper/Toaster";
import { Route, Switch, Redirect } from 'react-router-dom'
import DashboardLayout from './layouts/DashboardLayout'
class App extends React.Component {
  render() {
    if (localStorage.getItem('flash_app_stage') === 'partial' && window.location.pathname !== '/verifyEmail')
      window.location.replace('/verifyEmail')

    return (
      <div>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
        {!localStorage.getItem('luke_token') ? (
          <React.Fragment>
            <Header />
            <Switch>
              {OuterRoutes.map((prop, key) => {
                if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />
                return <Route exact path={prop.path} component={prop.component} key={key} />
              })}
            </Switch>
          </React.Fragment>
        ) : (
          <DashboardLayout>
            <Switch>
              {InnerRoutes.map((prop, key) => {
                if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />
                return <Route exact path={prop.path} component={prop.component} key={key} />
              })}
            </Switch>
          </DashboardLayout>
        )}
      </div>
    )
  }
}

export default App
