import React, { Component } from "react";

export default class InnerHeader extends Component {
  render() {
    return (
      <div></div>
      // <section className="sec-1-ctagory custm-cls-cate">
      //   <div className="container">
      //     <div className="inner-logo">
      //       <div className="logo-icns">
      //         <div className="nav-icn-lnk" data-toggle="collapse" data-target="#sidebarCollapsible" aria-expanded="true">
      //           <img src="/images/nav.png" alt="navbar" className="nav-icn" />
      //         </div>
      //       </div>
      //       <input type="text" id="searchInput" placeholder="Search something here" />

      //       <div className="srchbr-usricn">
      //         <div className="search-bar-catagory">
      //         </div>
      //         <div className="usr-icn dropdown" style={{ 'cursor': 'pointer' }}>
      //           <button className="addNewHeaderButton btn btn-default" ><i className="fa fa-plus"></i> Add New</button>
      //         </div>
      //         {window.innerWidth < 768 ?
      //           <div className="nav-icn-lnk text-center" data-toggle="collapse" data-target="#sidebarCollapsible" aria-expanded="true">
      //             <img src="/images/nav.png" alt="navbar" className="nav-icn" />
      //           </div>
      //           : null}
      //       </div>
      //     </div>
      //   </div>
      // </section>
    );
  }
}
