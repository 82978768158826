import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import $ from 'jquery'

let storageData = localStorage.getItem('active_user_data') ? JSON.parse(localStorage.getItem('active_user_data')) : {}
export default class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      links: [
        { link: '/home', name: 'Home', icon: 'home', liClasses: '' },
        { link: '/folder', name: 'Folders', icon: 'folder', liClasses: 'step7' },
        { link: '/sets', name: 'Sets', icon: 'file-text', liClasses: 'step9' },
        { link: '/category', name: 'Category', icon: 'th-large', liClasses: 'step11' },
      ],
      active: 0,
    }
  }
  // const location = useLocation();
  ActiveTab() {
    this.state.links.forEach((link, index) => {
      if (window.location.pathname === link.link) {
        $(`#tab${index}`).addClass('active')
      } else {
        $(`#tab${index}`).removeClass('active')
      }
    })
  }
  Nestedtabs() {
    if (window.location.pathname.startsWith('/folder/')) {
      $(`#tab${2}`).addClass('active')
    } else if (window.location.pathname.startsWith('/set/')) {
      $(`#tab${3}`).addClass('active')
    } else if (window.location.pathname.startsWith('/category/')) {
      $(`#tab${3}`).addClass('active')
    }
  }

  // componentDidMount() {
  //   this.ActiveTab()
  //   if (window.innerWidth > 768) {
  //     $('.sidebar').addClass('show')
  //   }
  // }
  componentDidUpdate() {
    this.ActiveTab()
    this.Nestedtabs()
    // if (window.innerWidth > 768) {
    //   $('.sidebar').addClass('show')
    // }
  }

  render() {
    // this.activeTab = (index) => {
    //   let tabToBeActive = document.querySelector(`#tab${index}`);
    //   let tabActive = document.querySelector(`#tab${this.state.active}`);
    //   if (index != this.state.active) {
    //     tabActive.classList.remove("active");
    //     this.setState({ active: index });
    //   }
    //   tabToBeActive.classList.add("active");
    // };

    return (
      <div className={`sidebar collapse step6 ${window.innerWidth > 768 ? 'show' : ''}`} id="sidebarCollapsible">
        <Link to="/">
          <div className="sidebar-heading">
            <img src="/images/lets-flash.png" alt="" width="60px" />
          </div>
        </Link>
        <div className="sidebar-header">
          <img src={'/images/user_img.png'} alt="" />
          <h3>Welcome!</h3>
          <h2 className="px-3">{`${storageData.first_name ? storageData.first_name : ''} ${
            storageData.last_name ? storageData.last_name : ''
          }`}</h2>
          <hr />
        </div>

        <nav className="sidebar-nav">
          <ul className="nav">
            {this.state.links &&
              this.state.links.map(({ link, name, icon, liClasses }, i) => {
                return (
                  <li
                    key={i}
                    data-toggle="collapse"
                    data-target="#sidebarCollapsible"
                    aria-expanded="true"
                    className={`nav-item ${liClasses}`}
                    id={`tab${i}`}
                    onClick={() => {
                      // this.props.setRun(false)
                      // console.log()
                      setTimeout(() => {
                        this.props.goToSlide(liClasses === 'step7' ? 7 : liClasses === 'step9' ? 9 : 11)
                        //   this.props.setRun(true)
                      }, 100)
                    }}
                  >
                    <Link className="nav-link" to={link}>
                      <i className={`nav-icon fa fa-${icon}`}></i>&ensp; {name}
                    </Link>
                  </li>
                )
              })}
          </ul>
        </nav>
      </div>
    )
  }
}
