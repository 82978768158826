import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Meta from '../../helper/seoMeta'
import OrganizationSchema from '../../schemas/Organization.json'
import WebpageSchema from '../../schemas/WebPage.json'
import BreadcrumbSchema from '../../schemas/BreadcrumbList.json'
import WebsiteSchema from '../../schemas/Website.json'

export class Dashboard extends Component {
  // onDownload=()=>{
  //   const isMac=navigator.userAgent.includes("Macintosh")
  //   const isiPhone=navigator.userAgent.includes("iPhone")
  //   if(isMac || isiPhone){
  //     window.open("https://www.apple.com")
  //   }else{
  //     window.open("http://www.google.com")
  //   }
  // }

  render() {
    return (
      <React.Fragment>
        <Meta
          title="LetsFlash - Virtual Study Assistant"
          desc="Virtual Study Assistant for any Occasion."
          canonical="https://letsflash.co/"
          schema={OrganizationSchema}
          schema2={WebpageSchema.home}
          schema3={BreadcrumbSchema.home}
          schema4={WebsiteSchema}
        />
        <section className="section_first">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h1>Virtual Study Assistant</h1>
                <p>
                  Study with efficiency by focusing on your competencies with an AI virtual assistant. Be able to study
                  on the move without interruption or pause.
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  {/* <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Email Address"
                    name
                  /> */}
                  {/* <button onClick={this.onDownload} type="button" className="btn btn-danger">
                    Download App
                </button> */}
                </div>
              </div>
              <div className="col-md-6">
                <img className="img-fluid screens_group" src="/images/screens_group.png" alt="screens_group" />
              </div>
            </div>
          </div>
          <img className="section_img section_img_01" src="/images/circle_group.png" alt="circle_group" />{' '}
        </section>
        {/* Section 02 */}
        <section className="section_sec" id="benefits">
          <div className="container text-center">
            <div className="row">
              <div className="col-12  col-md-6 col-lg-5 mx-auto">
                <h3>Key benefits of using Letsflash</h3>
              </div>
              <div className="col-12 col-md-10 col-lg-8 mx-auto">
                <div className="row">
                  <div className="col-12 col-md-4">
                    {' '}
                    <img className="img-fluid" src="/images/key_img_01.png" alt="key_img_01" />
                    <h4>Increase Subject Retention</h4>
                    <p>
                      Dedicate yourself to your study material by having a virtual study buddy in your pocket. One way
                      to increase Semantic memory or long term memory in a shorter time frame is acoustically, which can
                      be often ignored.
                    </p>
                  </div>
                  <div className="col-12 col-md-4">
                    {' '}
                    <img className="img-fluid" src="/images/key_img_03.png" alt="key_img_03" />
                    <h4>Study Wherever Whenver</h4>
                    <p>
                      Multitask when cleaning, walking your dog, meal prepping, and more in order to increase your
                      knowledge base.
                    </p>
                  </div>
                  <div className="col-12 col-md-4">
                    {' '}
                    <img className="img-fluid" src="/images/key_img_02.png" alt="key_img_02" />
                    <h4>Analyze Tendencies </h4>
                    <p>
                      With the help of Letsflash, you will be able to know daily what type of subject matter you are
                      lacking in to get the results you want through reports and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section 03 */}
        <section className="section_thrd">
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 ">
                {' '}
                <img className="img-fluid why_flesh" src="/images/why_flesh.png" alt="flash" />{' '}
              </div>
              <div className="col-12 col-md-5 col-lg-4">
                <div className="section_thrd_content w-100">
                  <h3>Why do you need Flash?</h3>
                  <p>
                    Share a database full of categories for various different subjects. Start learning immediately with
                    the help of machine learning to ensure the results that you need or want. Interacting with Letsflash
                    is a sure way to get the results you want in less time. Study with efficiency.
                  </p>
                  <Link to="#" className="learn_more">
                    LEARN MORE <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </Link>{' '}
                </div>
              </div>
            </div>
          </div>
          <img className="section_img section_img_02" src="/images/rectangle_01.png" alt="rectangle_01" />{' '}
          <img className="section_img section_img_03" src="/images/circle_01.png" alt="circle_01" />{' '}
        </section>
        {/* Section 04 */}
        <section className="section_four" id="mission">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 col-lg-5 offset-lg-1  ">
                <div className="section_four_content">
                  <h3>Our Mission</h3>
                  <p>
                    To help students of all ages to get the results that they need in educational environments or in
                    one's daily life. We want students to be able to study with efficiency. That is why LetsFlash was
                    developed. Machine Learning software that provides a virtual assistant that responds to students
                    with artificial intelligence.{' '}
                  </p>
                  <Link to="#" className="learn_more">
                    LEARN MORE <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-5 ">
                {' '}
                <img className="img-fluid our_mission_img" src="/images/mission.png" alt="mission" />{' '}
              </div>
            </div>
          </div>
          <img className="section_img section_img_04" src="/images/circles_bg.png" alt="circles_bg" />{' '}
        </section>
        {/* Section 05 */}
        <section className="section_five">
          <div className="container ">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h3 className="text-center">What users are saying:</h3>
                <div className="row">
                  <div className="col-md-4">
                    <div className="client_blg">
                      <div className="star_row">
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                      </div>
                      <p>
                        This app has helped me in so many ways with my course load. It helps with memorizing terms a
                        lot.{' '}
                      </p>
                      <div className="media">
                        {' '}
                        <img src="/images/CourtneyWilson.jpg" alt="user" className="mr-3 rounded-circle" />
                        <div className="media-body">
                          <h4>Courtney Wilson</h4>
                          <p>
                            Student <span></span>
                          </p>
                        </div>
                      </div>
                      <div className="clt_progress" style={{ width: '55%' }} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="client_blg">
                      <div className="star_row">
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                      </div>
                      <p>
                        I use Letsflash when I want to maximize the time I have to learn my study materials. Helpful
                        while doing tasks such as walking my dog, cooking, and more.{' '}
                      </p>
                      <div className="media">
                        {' '}
                        <img src="/images/StevenLee.jpg" alt="user" className="mr-3 rounded-circle" style={{}} />
                        <div className="media-body">
                          <h4>Steven Lee</h4>
                          <p>
                            Student at <span>University of Minnesota</span>
                          </p>
                        </div>
                      </div>
                      <div className="clt_progress" style={{ width: '55%' }} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="client_blg">
                      <div className="star_row">
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                        <i className="fa fa-star" aria-hidden="true" />
                      </div>
                      <p>
                        I feel very prepared with test and quizzes when I use this tool to solidify my course notes and
                        lectures.{' '}
                      </p>
                      <div className="media">
                        {' '}
                        <img src="/images/MichealaWatson.jpg" alt="user" className="mr-3 rounded-circle" style={{}} />
                        <div className="media-body">
                          <h4>Michaela Watson</h4>
                          <p>
                            Student at <span>University of Texas</span>
                          </p>
                        </div>
                      </div>
                      <div className="clt_progress" style={{ width: '100%' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="section_img section_img_05" src="/images/rectangle_02.png" alt="rectangle_02" />{' '}
        </section>
        {/* Section 06 */}
        <section className="section_six">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <h3 className="text-center">Join Our Newsletter</h3>
              </div>
              <div className="col-12 col-md-5 mx-auto">
                <div className="d-flex align-items-center justify-content-between">
                  <input type="text" className="form-control w-100" placeholder="Email Address" name="" />
                  <button type="button" className="btn btn-danger">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
          <img className="section_img section_img_06" src="/images/circle_02.png" alt="circle_02" />{' '}
          <img className="section_img section_img_07" src="/images/rectangle_03.png" alt="rectangle_03" />{' '}
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
