import React from 'react';

const Health = () => {
    return (
        <div className="container">
            <h3>Healthy App check for Load balancer!</h3>
        </div>
    );
}

export default Health;