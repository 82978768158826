// import React from "react";
// import Login from "../views/Login/Login";
import Dashboard from '../views/Outer/Dashboard'
import Login from '../views/Outer/Login'
import Register from '../views/Outer/Register'
import ForgotPassword from '../views/Outer/ForgotPassword'
import ResetPassword from '../views/Outer/ResetPassword'
import PrivacyPolicy from '../views/Outer/PrivacyPolicy'
import Health from '../views/Outer/Health'
import Questions from '../views/Questions'
import ConfirmEmail from '../views/VerifyEmail/ConfirmEmail'

const OuterRoutes = [
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/forgot_password',
    component: ForgotPassword,
  },
  {
    path: '/reset_password/:email',
    component: ResetPassword,
  },
  {
    path: '/category/:categoryName/:id',
    component: Questions,
    name: 'Questions',
    role: 'both',
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
    name: 'Confirm Email',
    role: 'both',
  },
  {
    path: '/health',
    component: Health,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },

  { redirect: true, path: '/', to: '/' },
]
export default OuterRoutes
