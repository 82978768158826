import Category from '../views/Category/Category'
import User from '../views/User/User'
import Profile from '../views/User/Profile'
import Folder from '../views/Folder/Folder'
import Sets from '../views/Sets/Sets'
import Home from '../views/Home/Home'
import Reports from '../views/Reports/Reports'
import Recent from '../views/Recents/Recents'
import Questions from '../views/Questions'
import VerifyEmail from '../views/VerifyEmail'
import ConfirmEmail from '../views/VerifyEmail/ConfirmEmail'

const InnerRoutes = [
  {
    path: '/user',
    component: User,
    name: 'User',
    role: 'admin',
  },
  {
    path: '/folder',
    component: Folder,
    name: 'Folders',
    role: 'both',
  },
  {
    path: '/folder/:folderName',
    component: Sets,
    name: 'Set',
    role: 'both',
  },
  {
    path: '/sets',
    component: Sets,
    name: 'Set',
    role: 'both',
  },
  {
    path: '/set/:setName',
    component: Category,
    name: 'Category',
    role: 'both',
  },
  {
    path: '/category',
    component: Category,
    name: 'Category',
    role: 'both',
  },
  {
    path: '/category/:categoryName',
    component: Questions,
    name: 'Questions',
    role: 'both',
  },
  {
    path: '/profile',
    component: Profile,
    name: 'Profile',
    role: 'both',
  },
  {
    path: '/reports',
    component: Reports,
    name: 'Reports',
    role: 'both',
  },
  {
    path: '/home',
    component: Home,
    name: 'Home',
    role: 'both',
  },
  {
    path: '/recents',
    component: Recent,
    name: 'Recents',
    role: 'both',
  },
  {
    path: '/verifyEmail',
    component: VerifyEmail,
    name: 'Verify Email',
    role: 'both',
  },
  // {
  //   path: "/test",
  //   component: test,
  //   name: "test",
  //   role: 'both'
  // },

  // {
  //   path: "/tests",
  //   component: tests,
  //   name: "tests",
  //   role: 'both'
  // },

  { redirect: true, path: '/', to: '/home' },
]
export default InnerRoutes
