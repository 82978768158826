import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Menu } from 'antd';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { toaster } from '../../helper/Toaster'
import { Howl, Howler } from 'howler';
import { getCard, addEditCard, deleteCard, findAccuracy, resultData, getCategoryByUserId } from '../../redux/actions/category'

import useDidUpdateEffect from '../../partials/useDidUpdateEffect'
import { ReactComponent as FlashCardIcon } from '../../assets/svgs/flashcard.svg'
import { ReactComponent as TestIcon } from '../../assets/svgs/test.svg'

import './Questions.css'
import FlashCard from './Components/CardContent/FlashCard';
import TestCard from './Components/CardContent/TestCard';
import AddEdit from './AddEdit';
import DeleteQuestionModal from './Components/DeleteQuestionModal';
import baseAudio from '../../assets/audios/notification.mp3'

// import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

const { Sider } = Layout;

let storageData = localStorage.getItem('active_user_data') ? JSON.parse(localStorage.getItem('active_user_data')) : {}

const Questions = props => {
  let location = useLocation();
  const { category_id, description, folder_id, name: category_name, recent_date, role, set_id, user_id, } = location.state || {}
  const { categoryName } = useParams();
  const { id } = useParams();
  const history = useHistory();
  const { question_list, result_response, accuracy_response, isAccuracyLoading, categories, add_response, edit_response, delete_response,
    getCard, deleteCard, findAccuracy, resultData, getCategoryByUserId, addEditCard } = props

  //Component States ---->
  const [categoryId, setCategoryId] = useState(false)
  const [showGoToReports, setShowGoToReports] = useState(false)
  const [toggleItemIndex, setToggleItemIndex] = useState(1)
  const [addEditFunc, setAddEditFunc] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [bulkQuestion, setBulkQuestion] = useState([])
  const [formData, setFormData] = useState({
    question: '',
    answer: '',
    hint: ''
  })
  const [isAdmin, setIsAdmin] = useState((storageData && storageData.login_user_id === user_id) || role === 'admin')

  useEffect(() => {
    if(id) {
      setCategoryId(id)
      getCard(id)
    }
    if (category_id) {
      setCategoryId(category_id)
      getCard(category_id)
    } else if (!category_id) {
      if (storageData) getCategoryByUserId(storageData.login_user_id)
    }
  }, [])
  useEffect(() => {
    if (category_id) {
      setCategoryId(category_id)
    }
  }, [category_id])

  useDidUpdateEffect(() => {
    let catId
    if (categories && (categories.code === 200)) {
      categories.data.forEach(cat => {
        if (cat.name.toLowerCase() === categoryName.toLowerCase()) {
          catId = cat.category_id
        }
      })
      console.log("ID: ", catId)
      setCategoryId(catId)
      getCard(catId)
    }
  }, [categories])

  useDidUpdateEffect(() => {
    if (result_response && result_response.code === 200) {
      setShowGoToReports(true)
    }
  }, [result_response])

  useDidUpdateEffect(() => {
    if (add_response) handleAddEditDeleteResponse(add_response)
  }, [add_response])
  useDidUpdateEffect(() => {
    if (edit_response) handleAddEditDeleteResponse(edit_response)
  }, [edit_response])
  useDidUpdateEffect(() => {
    if (delete_response) handleAddEditDeleteResponse(delete_response)
  }, [delete_response])
  const handleAddEditDeleteResponse = (res) => {
    if (res && res.code === 200) {
      toaster('success', res.message)
      setAddEditFunc(null)
      getCard(categoryId)
    } else if (res && res.code === 400) {
      toaster('error', edit_response.message)
    }
  }

  const handleActions = (e, flag, data) => {
    if (flag === 'addMore') {
      setBulkQuestion([...bulkQuestion, { question: '', answer: '', hint: '' }])
    } else if (flag === 'add' && isAdmin) {
      setAddEditFunc('add')
      setBulkQuestion([{ question: '', answer: '', hint: '' }])
    } else if (flag === 'edit' && isAdmin) {
      setAddEditFunc('edit')
      setBulkQuestion(data)
    } else if (flag === 'cross') {
      const tempBulkQuestion = bulkQuestion.filter((item, i) => i !== data)
      setBulkQuestion(tempBulkQuestion)
    } else if (flag === 'viewBack') {
      setAddEditFunc(null)
    } else if (flag === 'delete') {
      setSelectedQuestion(data)
      setShowModal(true)
    }
  }
  const handleChange = (e, name, index) => {
    if (name === 'question' && /^[A-Z a-z$&+,:;=?@#|'<>.^*()%!-]{0,200}$/.test(e.target.value) === false) {
      return toaster('error', 'Question should be at most 200 chars long')

    } else if (name === 'answer' && /^[A-Z a-z$&+,:;=?@#|'<>.^*()%!-]{0,500}$/.test(e.target.value) === false) {
      return toaster('error', 'Answer should be at most 500 chars long')

    } else if (name === 'hint' && /^[A-Z a-z$&+,:;=?@#|'<>.^*()%!-]{0,100}$/.test(e.target.value) === false) {
      return toaster('error', 'Hint should be at most 100 chars long')
    }
    setFormData({ ...formData, [name]: e.target.value })
    const editBulk = bulkQuestion
    editBulk[index][name] = e.target.value
    setBulkQuestion(editBulk)
  }
  const goBackToCategory = () => {
    history.goBack()
  }
  const onToggleSidebar = (index) => {
    const sound = new Howl({
      src: [baseAudio],
      volume: 0,
      autoUnlock: true,
      preload: true,
    })
    sound.play()
    setToggleItemIndex(index)
  }
  const submitData = async (e, flag) => {
    let formData = new FormData()
    if (bulkQuestion.some(data => data.question === '' || data.answer === '')) {
      return toaster('error', 'Please fill all the fields')
    } else {
      if (flag === 'add') {
        formData.append('category_id', categoryId)
        formData.append('question_data', JSON.stringify(bulkQuestion))
        addEditCard(formData, 'add')
      } else if (flag === 'edit') {
        formData.append('category_id', categoryId)
        formData.append('question_data', JSON.stringify(bulkQuestion))
        addEditCard(formData, 'edit')
      }
    }
  }
  const onDelete = async (data) => {
    if (data) {
      await deleteCard(data.card_content_id)
      setShowModal(false)
      await getCard(data.categoryId)
    }
  }

  return (
    <>
      <DeleteQuestionModal showModal={showModal} setShowModal={setShowModal} onDelete={onDelete} data={selectedQuestion} />
      {addEditFunc === 'add' || addEditFunc === 'edit' ? (
        <AddEdit
          bulk_question={bulkQuestion}
          addEditFunc={addEditFunc}
          handleChange={handleChange}
          handleActions={handleActions}
          submitData={submitData}
        />
      ) : (
        <div className="container px-md-5 px-sm-1 py-4">
          <div className="row">
            <button onClick={goBackToCategory} type="button" className={`button_add_edit btn mx-1 ml-auto`}> BACK </button>
            {question_list && question_list.length ? (
              <button onClick={e => handleActions(e, 'edit', question_list)} type="button" className={`button_add_edit btn mx-1`}> EDIT </button>
            ) : (
              <button onClick={e => handleActions(e, 'add')} type="button" className={`button_add_edit btn mx-1`}> ADD </button>
            )}
            {showGoToReports ? (
              <Link to={{
                pathname: `/reports`,
                state: { categoryId }
              }}>
                <button type="button" className={`button_goto_reports btn mx-1`}>REPORTS</button>
              </Link>
            ) : null}
          </div>
          <div className="row instructionText">
            {toggleItemIndex === 2 ? (
              <p className="py-2"> To get hints please speak 'Show me hint' and if you don't know the answer please speak 'I don't know'</p>
            ) : null}
          </div>
          <div className="row my-md-5">
            <Sider theme="light" className="site-layout-background siderCustom d-block d-md-none" >
              <div className="logo px-1 py-3 font-weight-bold text-muted">STUDY</div>
              <Menu mode="horizontal" defaultSelectedKeys={['1']} className="" style={{ fontWeight: "500" }}>
                <Menu.Item key="1" icon={<FlashCardIcon />} onClick={() => onToggleSidebar(1)}>Flashcards</Menu.Item>
                <Menu.Item key="2" icon={<TestIcon />} onClick={() => onToggleSidebar(2)}>Test</Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{ padding: '0 0', background: '#fff' }}>
              <Sider theme="light" breakpoint={"lg"} className="site-layout-background siderCustom d-none d-md-block" >
                <div className="logo px-1 py-3 font-weight-bold text-muted">STUDY</div>
                <Menu mode="inline" defaultSelectedKeys={['1']} className="" style={{ fontWeight: "500" }}>
                  <Menu.Item key="1" icon={<FlashCardIcon />} onClick={() => onToggleSidebar(1)}>Flashcards</Menu.Item>
                  <Menu.Item key="2" icon={<TestIcon />} onClick={() => onToggleSidebar(2)}>Test</Menu.Item>
                </Menu>
              </Sider>
              {toggleItemIndex === 1 ? (
                <FlashCard question_list={question_list} deleteCard={deleteCard} getCard={getCard} />
              ) : toggleItemIndex === 2 ? (
                <TestCard question_list={question_list} handleActions={handleActions} isAdmin={isAdmin}
                  findAccuracy={findAccuracy} getCard={getCard} accuracy_response={accuracy_response}
                  category_id={categoryId} resultData={resultData} storageData={storageData} result_response={result_response}
                  isAccuracyLoading={isAccuracyLoading}
                />
              ) : null}
            </Layout>
          </div>
        </div>
      )}
    </>

  )
}

Questions.propTypes = {
  //   user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  question_list: state.category.question_list.data,
  add_response: state.category.add_response,
  edit_response: state.category.edit_response,
  delete_response: state.category.delete_response,
  accuracy_response: state.category.accuracy_response,
  result_response: state.category.result_response,
  isAccuracyLoading: state.category.isAccuracyLoading,
  categories: state.category.category_list
})

const mapDispatchToProps = dispatch => {
  return {
    getCard: params => dispatch(getCard(params)),
    addEditCard: (params, flag) => dispatch(addEditCard(params, flag)),
    deleteCard: params => dispatch(deleteCard(params)),
    findAccuracy: params => dispatch(findAccuracy(params)),
    resultData: params => dispatch(resultData(params)),
    getCategoryByUserId: params => dispatch(getCategoryByUserId(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions)
