import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Header extends Component {
  pathName = window.location.pathname
  regex = /\d/ //to see if number exist in Path
  
  render() {
    const isMobileQuestion = this.regex.test(this.pathName)
    console.log(this.pathName)
    console.log(isMobileQuestion)
    return (
      !isMobileQuestion ?
      <header>
        <nav className="navbar navbar-expand-md fixed-top">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img className="img-fluid" src="/images/logo.png" alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ml-auto">
                {window.location.pathname === "/" && (
                  <React.Fragment>
                    <li className="nav-item active">
                      <a className="nav-link smoothscroll" href="#benefits" data-toggle="collapse" data-target=".navbar-collapse.show">
                        Benefits
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link smoothscroll" href="#mission" data-toggle="collapse" data-target=".navbar-collapse.show">
                        Mission
                      </a>
                    </li>
                  </React.Fragment>
                )}
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <Link to="/login" className="btn">
                    Login
                  </Link>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <Link to="/register" className="btn">
                    Sign Up
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header> : null
    );
  }
}
